








































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import AProgressBar from "@/components/controls/AProgressBar.vue";

import ASquareIconBtn from "@/components/controls/ASquareIconBtn.vue";

import AAuditItemsListFilter from "@/components/widgets/AAuditItemsListFilter.vue";
import MeasureListWidget from "@/components/widgets/MeasureListWidget.vue";
import AAuditOverview from "@/components/widgets/AAuditOverview.vue";
import {
  WorkflowActionPayload,
  WorkflowActionIds,
  WorkflowAction,
} from "@auditcloud/shared/lib/types/WorkflowActions";
import { ROUTE_NAMES, dialogRoute, DIALOG_NAMES } from "@/routenames";

import { api as auditApi } from "@/store/modules/audit";
import { api as confApi } from "@/store/modules/configuration";

const auditModule = namespace(auditApi.namespace);
const confModule = namespace(confApi.namespace);

@Component({
  components: {
    MeasureListWidget,
    AAuditOverview,
    ASquareIconBtn,
    AAuditItemsListFilter,
    AProgressBar,
  },
})
export default class AAuditActionlist extends Vue {
  xMode: "table" | "list" | null = null;

  get mode(): "table" | "list" {
    return this.xMode ?? this.actionplanView;
  }

  set mode(mode: "table" | "list") {
    this.xMode = mode;
  }

  @Prop({
    type: String,
  })
  readonly auditId!: string;

  @auditModule.Getter(auditApi.getters.getActionsPerView)
  actionsPerView!: (viewname: string) => string[];

  @auditModule.Getter(auditApi.getters.getMeasureProcessCompletionPercentage)
  measureProcessCompletionPercentage!: number;

  @auditModule.Getter(auditApi.getters.getActionplanView)
  actionplanView!: "table" | "list";

  @confModule.Getter(confApi.getters.externalMeasureFeatureEnabled)
  externalMeasureFeatureEnabled!: boolean;

  readonly dialogRouteName = dialogRoute(
    ROUTE_NAMES.AUDITACTIONLIST,
    DIALOG_NAMES.MEASURE
  );

  isIntersecting: boolean = false;
  offsetTop: number = 0;

  onScroll(e: any) {
    if (window.pageYOffset > 70) {
      this.isIntersecting = true;
    } else {
      this.isIntersecting = false;
    }
  }

  mounted() {
    const actionMap: { [id: string]: WorkflowAction } = {
      [WorkflowActionIds.UPLOAD_ACTIONPLAN]: {
        id: WorkflowActionIds.UPLOAD_ACTIONPLAN,
        text: `${this.$t("views.audit_actionslist.upload_actionplan")}`,
        icon: "mdi-paperclip",
        handler: () => {
          const newRoute = {
            name: this.$route.name ?? "error",
            params: {
              ...this.$route.params,
              dialogName: DIALOG_NAMES.AUDIT_ATTACHMENTS,
            },
            query: this.$route.query,
          };
          this.$router.push(newRoute);
        },
      },
    };

    if (this.externalMeasureFeatureEnabled) {
      actionMap[WorkflowActionIds.ACTIVATE_EXTERNAL_MEASURES] = {
        id: WorkflowActionIds.ACTIVATE_EXTERNAL_MEASURES,
        text: `${this.$t(
          "views.audit_actionlist.start_external_measure_processing"
        )}`,
        icon: "mdi-remote-desktop",
        handler: this.openExternalMeasureSidebar,
      };
    }
    const actionsList = this.actionsPerView("actionplan")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };

    this.$emit("actions", actions);
  }

  openExternalMeasureSidebar() {
    const newRoute = {
      name: this.$route.name ?? "error",
      params: {
        ...this.$route.params,
        dialogName: DIALOG_NAMES.ACTIVATE_EXTERNAL_MEASURE_PROCESSING,
      },
    };
    this.$router.push(newRoute);
  }
}
